@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  font-family: "Fira Sans Extra Condensed", sans-serif !important;
}

.font-dm-sans {
  font-family: "DM Sans", sans-serif !important;
}

@layer base {
  :root {
    --border: 214.3 31.8% 91.4%;
  }
}

.Toastify__toast {
  font-size: 14px;
  border-radius: 8px;
  padding: 6px;
}

.Toastify__toast--success {
  background-color: #ECFDF5 !important;
  color: #065F46 !important;
}

.Toastify__toast--error {
  background-color: #FEE2E2 !important;
  color: #991B1B !important;
}

.Toastify__toast--warning {
  background-color: #FFFBEB !important;
  color: #92400E !important;
}

.Toastify__toast--info {
  background-color: #EFF6FF !important;
  color: #1E40AF !important;
}

.loader_area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100%;
  background-color: #ffffff;
  margin: 0;
  position: absolute;
  z-index: 100;
}

.loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #08785a;
  border-radius: 50%;
  animation: pulse 1.2s infinite ease-in-out;
}

.circle:nth-child(1) {
  animation-delay: -0.4s;
}

.circle:nth-child(2) {
  animation-delay: -0.3s;
}

.circle:nth-child(3) {
  animation-delay: -0.2s;
}

.circle:nth-child(4) {
  animation-delay: -0.1s;
}

.ql-toolbar {
  background: #f0f0f0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@keyframes pulse {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes pulse-2 {
  0%, 100% { opacity: 1; }
  50% { opacity: .5; }
}

.animate-pulse-2 {
  animation: pulse-2 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.eval-badge::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-color: transparent;
  bottom: 0px;
  left: 4px;
  transform: translateY(100%);
  height: 50px;
  width: 16px;
  border-top-left-radius: 16px;
  box-shadow: 0 -16px 0 0 hsla(231, 100%, 53%, 1);
}

.eval-badge > * {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.eval-badge {
  line-height: 100%;
  letter-spacing: -0.02em;
}

@keyframes fadeIn {
  from { opacity: 0.25; }
  to { opacity: 1; }
}

@keyframes moveRight {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

@keyframes badgeSlide {
  0% { transform: translateX(-10px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}
